<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="alertMsg">
        <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
              {{alertMsg}}
        </b-alert>
    </div>
    <!-- <div class="alertMsg">
    <b-alert v-model="showDismissibleAlert" variant="alertvariant" dismissible>
        {{notificationText}}
    </b-alert>
  </div> -->

    <form @submit.prevent="submitEmployee" method="post">

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label class="form-label" for="formrow-empName-input">Name *</label>
                                <input type="text" class="form-control" id="formrow-name-input" v-model="empName" :class="{
                                'is-invalid': submitted && $v.empName.$error,
                            }">
                                <div v-if="submitted && $v.empName.$error" class="invalid-feedback">
                                    <span v-if="!$v.empName.required">This value is required.</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Date of Joining</label>
                                <br />
                                <input type="date" class="form-control" id="formrow-licenseNO-input" v-model="dateJoining">
                                
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class=" col-6">
                                <label class="form-label" for="formrow-empEmailID-input">EmailID *</label>
                                <input type="text" class="form-control" id="formrow-empEmailID-input" v-model="empEmailID" :class="{
                                    'is-invalid': submitted && $v.empEmailID.$error,
                                }">
                                <div v-if="submitted && $v.empEmailID.$error" class="invalid-feedback">
                                    <span v-if="!$v.empEmailID.required">This value is required.</span>
                                </div>
                            </div>
                          <div class="col-6">
                            <label class="form-label">Phone No.*</label>
                            <input class="form-control" type="number" min="0"  v-model="phoneNO" :class="{
                                'is-invalid': submitted && $v.phoneNO.$error,
                              }">

                            <div v-if="submitted && $v.phoneNO.$error" class="invalid-feedback">
                              <span v-if="!$v.phoneNO.required">This value is required.</span>
                              <span v-if="!$v.phoneNO.maxLength">This value is too long. It should have 10 digits</span>
                              <span v-if="!$v.phoneNO.minLength">This value length is invalid. It should be 10 digits</span>
                            </div>
                          </div>
<!--                            <div class="col-md-6">-->
<!--                              <label class="form-label" for="formrow-desgination-input">Designation *</label>-->
<!--                              <multiselect v-model="empTypeID" :options="employeeTypeArr" track-by="empTypeID" label="role" :class="{-->
<!--                                'is-invalid': submitted && $v.empTypeID.$error,}"></multiselect>-->
<!--                              <div v-if="submitted && $v.empTypeID.$error" class="invalid-feedback">-->
<!--                                <span v-if="!$v.empTypeID.required">This value is required.</span>-->
<!--                              </div>-->
<!--                            </div>                            -->
                        </div>

                        <div class="row">
            <div class="col-md-6 mb-3">
                       <label class="form-label">City</label>
                         <multiselect v-model="city" :options="cityArr" 
                         track-by="city" label="city"></multiselect>
                    </div>
                            <!-- <div class="row"> -->
                          <div class="mb-3 col-6">
                            <label> Login Type<sup>*</sup> : </label>
                            <multiselect
                                v-model="loginType"
                                :options="loginTypeArr"
                                :show-labels="false"
                                track-by="id"
                                label="name"
                                :class="{'is-invalid': submitted && $v.loginType.$error,}"
                                @input="loadRoleTypeData()"
                            >
                            </multiselect>
                            <div v-if="submitted && $v.loginType.$error" class="invalid-feedback">
                              <span v-if="!$v.loginType.required">This value is required.</span>
                            </div>
                          </div>
                          <div class="mb-3 col-6">
                            <label> Role<sup>*</sup> :</label>
                            <multiselect
                                v-model="role"
                                :options="roles"
                                :show-labels="false"
                                track-by="id"
                                label="name"
                                :class="{'is-invalid': submitted && $v.loginType.$error,}"
                            >
                            </multiselect>
                            <div v-if="submitted && $v.role.$error" class="invalid-feedback">
                              <span v-if="!$v.role.required">This value is required.</span>
                            </div>
                          </div>
                            <div class="col-md-6 mb-3">
                                <label>Select Restaurant Branch </label>
                                <multiselect v-model="branchID" :options="branches" track-by="restBranchID" label="branchName" :multiple="true"></multiselect>
                            <!-- {{branchID}} -->
                                <!-- <multiselect v-model="branchID" :options="branches" track-by="restBranchID" label="branchName" :multiple="this.empTypeID.empTypeID==9 ? true : false" :show-labels="false"></multiselect> -->
                            </div>

                        </div>
                        <div class=" row" style="float:right;">
                            <b-spinner v-if="loading" class="m-2 col-3" variant="primary" role="status"></b-spinner>
                            <button type="submit" v-if="!isEdit && $can('create cloudkitch staff')" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                            <button type="submit" v-if="isEdit && $can('update cloudkitch staff')" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </form>

</Layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Employee",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Employee",
      items: [
        {
          text: "Add Employee",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      // for edit
      empData: [],
      isEdit: false,
      empID: 0,
      data: "",
      city: "",
      cityArr:[],
      branchID: [],
      nowDate: new Date().toISOString().slice(0, 10),
      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      notificationText: "",
      alertvariant: "",
      dateJoining: "",
      employeeTypeArr: [],
      empTypeID: "",
      branches:"",
      empName: "",
      empEmailID: "",
      phoneNO: "",
      loginTypeArr: [
        { id: 1,name: "CloudKitch Admin" },
        { id: 2,name: "Restaurant" },
        { id: 3,name: "Corporate" },
        { id: 4,name: "Principle" },
      ],
      loginType: "",
      roles:[],
      role:"",
    };
  },

  validations: {
    empName: {
      required,
    },
    empEmailID: {
      required,
      email,
    },
    phoneNO: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },
    loginType:{
      required
    },
    role:{
      required
    }
  },
  methods: {
    loadRoleTypeData() {
      this.loading = true;
      this.axios
          .get(this.$loggedRole+"/login-type-roles/"+this.loginType.id)
          .then((result) => {
            this.roles = result.data.data;
            this.loading = false;
          });

    },
    submitEmployee(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        /*
                     Add the form data we need to submit
                 */
        formData.append("employee_id", this.empID); // for edit
        formData.append("emailID", this.empEmailID);
        formData.append("phoneNO", this.phoneNO);
        formData.append("name", this.empName);
        // formData.append("empTypeID", this.empTypeID.empTypeID);
        formData.append("city", this.city != "" ? this.city.city : "");
        formData.append("restBranchID", JSON.stringify(this.branchID));
        formData.append("dateOfJoining", this.dateJoining);
        formData.append("login_type", this.loginType.id);
        formData.append("role", JSON.stringify(this.role));

        e.preventDefault();
        //let headers = authHeader();
        let apiName = "addCloudkitchAdmin";
        let routerPush = "cloudkitchEmployeeForm";
        if (this.isEdit == true) {
          apiName = "update-cloudkitch-staff";
          routerPush = "cloudkitchEmployeeTable";
        }
        this.axios
            .post(this.$loggedRole+"/"+apiName, formData)
            .then((result) => {
                this.loading = false;
                this.$swal({
                  text: result.data.message,
                  icon: "success",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000
                });
                setTimeout(() => {
                  this.$router.push({ name: routerPush });
                }, 2000);

            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },

    getEmpByID(empID) {
      this.axios.get(this.$loggedRole+"/cloudkitch-employee/"+empID+"/edit")
          .then((result) => {
            this.empData = result.data.data;
            this.empID = this.empData.empID;
            this.empName = this.empData.name;
            this.empEmailID = this.empData.emailID;
            this.dateJoining = this.empData.dateOfJoining;
            this.phoneNO = this.empData.phoneNO;
            this.role = this.empData.role;
            this.loginType = this.empData.login_type;
            this.branchID = this.empData.branchData;
            this.city = this.empData.city;
             this.getAllRestaurantBranches();
               this.loadRoleTypeData();
          });
    },
    // for edit
    getEmployeeDesignation() {
      this.axios.post(this.$loggedRole+"/getEmployeeDesignation", {
        loginTypeID: 1
      })
          .then((result) => {
            this.employeeTypeArr = result.data.data;
            // console.log(this.employeeTypeArr);
            //  this.getAllCities();
          });
    },
    getAllRestaurantBranches() {
      this.axios
          .post(this.$loggedRole+"/getAllRestaurantBranches", {
            restBranchID: 0,
            city: this.city,
            //isDashboard : 1, // for all dropdown in only dashboards
          })
          .then((response) => {
            this.branches = response.data.data;
          });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
          })
          .then((response) => {
            this.cityArr = response.data.data;
          });
    },
  },

  mounted() {
    // this.getEmployeeDesignation();
    this.getAllRestaurantBranches();
    this.getAllCities()
    // for edit
    if (this.$route.params.type == "edit") {
      if (this.$route.params.id) {
        sessionStorage.setItem("empID", this.$route.params.id);
      }
    } else {
      sessionStorage.setItem("empID", 0);
    }
    this.empID = sessionStorage.getItem("empID");
    if (this.empID > 0) {
      this.getEmpByID(this.empID);
      this.isEdit = true;
    }
  },
  middleware: "authentication",
};
</script>
<style>
.multiselect__content-wrapper {
  max-height: 150px !important;
}
img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>
